import React, { FC } from 'react'
import styled from '@emotion/styled'
import SEO from '@/components/head/SEO'
import { rem } from '@/utils/helpers'
import Section from '@/components/layouts/Section'
import Container from '@/components/layouts/Container'
import Typography from '@/components/typography'
import Button from '@/components/snippets/Button'

interface Props {
  [key: string]: any
}

const PrivacyPolicy: FC<Props> = () => (
  <Root>
    <SEO title="個人情報保護方針" description="" />
    <Section color="black.0">
      <Container type={3}>
        <Head>
          <Title variant="h3" component="h1">
            個人情報保護方針
          </Title>
        </Head>
        <Main>
          <Content>
            <h2>個人情報の利用目的</h2>
            <p>
              当サイトでは、お問い合わせの際に、名前（ハンドルネーム）、メールアドレス等の個人情報をご登録いただく場合がございます。これらの個人情報は質問に対する回答や必要な情報を電子メールなどをでご連絡する場合に利用させていただくものであり、個人情報をご提供いただく際の目的以外では利用いたしません。
            </p>
            <h2>個人情報の第三者への開示</h2>
            <p>
              当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。
            </p>
            <ul>
              <li>本人のご了解がある場合</li>
              <li>法令等への協力のため、開示が必要となる場合</li>
            </ul>
            <h2>個人情報の開示、訂正、追加、削除、利用停止</h2>
            <p>
              ご本人からの個人データの開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます。
            </p>
            <h2>アクセス解析ツールについて</h2>
            <p>
              当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
            </p>
            <h2>免責事項</h2>
            <p>
              当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございます。当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
            </p>
            <h2>プライバシーポリシーの変更について</h2>
            <p>
              当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。修正された最新のプライバシーポリシーは常に本ページにて開示されます。
            </p>
          </Content>
        </Main>
        <Foot>
          <Button to="/" color="primary" iconAfter="right">
            サイトトップへ
          </Button>
        </Foot>
      </Container>
    </Section>
  </Root>
)

const Root = styled.div``

const Head = styled.div``

const Title = styled(Typography)`
  text-align: center;
`

const Main = styled.div`
  margin-top: ${rem(40)};
`

const Content = styled.div`
  h2 {
    margin: ${rem(24)} 0 ${rem(12)};
    font-size: ${rem(16)};
    font-weight: bold;
    line-height: 1.3;

    &:first-of-type {
      margin-top: 0;
    }
  }
  p {
    font-size: ${rem(14)};
    line-height: 1.8;
  }

  ul {
    margin-top: ${rem(12)};
    li {
      margin-top: ${rem(4)};
      font-size: ${rem(14)};
      line-height: 1.5;
      &::before {
        content: '・';
      }
    }
  }
`

const Foot = styled.div`
  margin-top: ${rem(48)};
  display: flex;
  justify-content: center;
`

export default PrivacyPolicy
